import '../assets/global.scss';
import Swal from 'sweetalert2';

const  dateFormater = new Intl.DateTimeFormat('pt-BR',{
  dateStyle:'short',
  timeStyle: 'short'
});

export const formatDate = (value) => dateFormater.format(new Date(value))
  
  export const LimitString = (data) => {
     if(data.length > 40)
        return data.substring(0,15)
    
    return data;    
  };

  export const SetLocalSotarage = (number_client,id_company,id_sector,number_bot,name_sector,avatar,name_client = '', name_company = '', id_group_external = '') => {

    localStorage.setItem('@sac-v1.0.0-number_client',number_client);
    localStorage.setItem('@sac-v1.0.0-id_company',id_company);
    localStorage.setItem('@sac-v1.0.0-id_sector',id_sector);
    localStorage.setItem('@sac-v1.0.0-number_bot',number_bot);
    localStorage.setItem('@sac-v1.0.0-name_sector',name_sector);
    localStorage.setItem('@sac-v1.0.0-avatar',avatar);
    localStorage.setItem('@sac-v1.0.0-name_client',name_client);
    localStorage.setItem('@sac-v1.0.0-name_company',name_company);
    localStorage.setItem('@sac-v1.0.0-id_group_external',id_group_external)
  
  }

  export const GetLocalStorage = () => {
      localStorage.setItem('@sac-v1.0.0-number_client',"");
      localStorage.setItem('@sac-v1.0.0-id_company',"");
      localStorage.setItem('@sac-v1.0.0-id_sector',"");
      localStorage.setItem('@sac-v1.0.0-number_bot',"");
      localStorage.setItem('@sac-v1.0.0-name_sector',"");
      localStorage.setItem('@sac-v1.0.0-avatar',"");
      localStorage.setItem('@sac-v1.0.0-name_client',"");
      localStorage.setItem('@sac-v1.0.0-name_company',"");
      localStorage.setItem('@sac-v1.0.0-id_group_external',"")
  }

  export const Alert = (type, title, text, options = {}) => Swal.fire({
    ...options,
    type,
    title,
    text,
    customClass: { 
      container: 'my-swal' 
    }
  });

  export const DetectBrowser = () => {

    let sUsrAg = navigator.userAgent;
    let type;

    if(sUsrAg.indexOf("Chrome") > -1) {
      type = "audio/webm";
    } else if (sUsrAg.indexOf("Firefox") > -1) {
      type = "audio/ogg";
    }

    return type;
  }